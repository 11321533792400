import * as React from "react";
import { ThemeProvider as EmotionThemeProvider } from "@emotion/react";
import { default as defaultTheme } from "src/components/theme";
import Header from 'src/components/header';

export default function ThemeProvider({ children, theme = {}, siteTitle }) {
  return (
	<EmotionThemeProvider theme={{ ...defaultTheme, ...theme }}>
	<Header siteTitle={siteTitle} />
	  {children}
	</EmotionThemeProvider>
  );
}