import React from 'react';

export default function PdfComponent(props) {
  return (
      <embed 
        src={props.pdfData} 
        width="100%"
        margin="auto"
        height="800" 
        type="application/pdf">
      </embed>
  )
};