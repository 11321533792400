import React from "react";
// import Code from "src/components/mdx/code";
// import CodeBlock from "src/components/mdx/CodeBlock";
import AnchorTag from "src/components/mdx/anchor";

/* eslint-disable react/display-name */
export default {
  // code: CodeBlock,
  // inlineCode: props => <Code {...props} />,
  //a: props => <AnchorTag {...props} />,
  p: props => <p {...props} style={{ fontSize: "18px", lineHeight: 1.6 }} />,
  ul: props => <ul {...props} style={{ fontSize: "18px"}} />,
  li: props => <li {...props} style={{ fontSize: "18px"}} />,
  table: props => <table {...props} className="parsertongue-table" />,
  tr: props => <tr {...props} className="parsertongue-table-row" />
  // TODO add `img`
  // TODO add `blockquote`
};