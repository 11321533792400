import React from 'react'
import { Link } from 'gatsby'
import { 
  GitHubPage, 
  TwitterAccount,
  ORCIDProfile,
  GoogleScholarProfile 
} from 'src/components/icons'

function Footer() {

    return (
        <div
            style={{
                //background: 'grey',
                position: 'fixed',
                bottom: '0',
                width: '100%',
                height: '4em'
            }}
        >
          <div
              style={{
                margin: 'auto',
                display: 'block',
                width: '50%',
                paddingTop: '0px',
                paddingBottom: '2px',
              }}
          >             
            <div>
              <a 
                rel="home" 
                href="/" 
                style={{
                  position: "absolute",
                  fontFamily: "monospace",
                  right: "14px",
                  margin: 0,
                  marginTop: "-16px",
                  paddingTop: "0px"
                }}
              >
                cd ~/
              </a> 
              <a 
                rel="license" href="http://creativecommons.org/licenses/by-nc/4.0/"><img alt="Creative Commons License" 
                style={{
                  position: "absolute",
                  right: "10px",
                  margin: 0,
                  marginTop: "8px",
                  paddingRight: "4px",
                  }} 
                  src="https://licensebuttons.net/l/by-nc/4.0/88x31.png" />
              </a>
            </div>
          </div>
        </div>
    )
}



  const styles = {
    headerTitle: {
      color: 'white',
      fontSize: '20px',
      textDecoration: 'none',
      marginLeft: "2%",
      marginRight: "2%",
    },
    link: {
      cursor: 'pointer',
      color: 'white',
      textDecoration: 'underline'
    },
    divider: {
      color: 'black',
      fontSize: '20px'
    },
    icon: {
      height: '20px',
      paddingBottom: '0px',
      marginBottom: '-3px',
      color: 'white'
    }
  }
  
  export default Footer