import React from 'react'

//import Img from "gatsby-image/withIEPolyfill";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby";

function Image() {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "ghp.jpg" }) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          gatsbyImageData(
            width: 600
            height: 400
            # tracedSVG
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `)
  const profile = data.file.childImageSharp.gatsbyImageData
  return (
      <GatsbyImage
        image={profile}
        alt="GHP"
        style={{
          ...styles.avatar,
        }}
      />
  )
}

const styles = {
  avatar: {
    borderRadius: "10%",
    marginBottom: "0.5cm",
    marginTop: "0.5cm"
  }
}

export default Image;