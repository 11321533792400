import React from 'react'
import { FaGithub, FaTwitter, FaLinkedinIn } from 'react-icons/fa'

// https://react-icons.netlify.com/#/

function GitHubPage(props) {
    const url = `https://github.com/${props.account}`
    return (
        <a href={url}>
            <FaGithub
            style={{...props.style}}
            />
        </a>
        )
}


function TwitterAccount(props) {
    const url = `https://twitter.com/${props.account}`
    return (
        <a href={url}>
            <FaTwitter
            style={{...props.style}}
            />
        </a>
        )
}

function ORCIDProfile(props) {
    const url = `https://orcid.org/0000-0002-7957-3586`
    return (
        <a href={url}>
            <i className="ai ai-orcid"
            style={{
                ...props.style,
                // fontSize: "0.55em",
            }}
            />
        </a>
    )
}

function GoogleScholarProfile(props) {
    const url = `https://scholar.google.com/citations?user=AV6nozIAAAAJ&hl=en`
    return (
        <a href={url}>
            <i className="ai ai-google-scholar"
            style={{
                ...props.style,
                fontSize: "0.55em",
            }}
            />
        </a>
    )
}

function LinkedInProfile(props) {
  const url = `https://www.linkedin.com/in/gushahnpowell/`
  return (
    <a href={url}>
      <FaLinkedinIn
        style={{...props.style}}
      />
    </a>
  )
}

export {
    GitHubPage,
    TwitterAccount,
    ORCIDProfile,
    GoogleScholarProfile,
    LinkedInProfile
}