import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { MDXProvider } from "@mdx-js/react"
import ThemeProvider from "src/components/ThemeProvider"
import mdxComponents from "src/components/mdx"
import { useSiteMetadata } from "src/hooks/use-site-metadata"
// import { useStaticQuery, graphql } from 'gatsby'

// import Header from './header'
import Footer from 'src/components/footer'

import './layout.css';

const Wrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 767px) {
    display: block;
  }
`;

const Content = styled('main')`
  display: flex;
  flex-grow: 1;
  margin: 0px 88px;
  margin-top: 3rem;
  padding-top: 4em;
  @media only screen and (max-width: 1023px) {
    padding-left: 0;
    margin: 0 10px;
    margin-top: 3rem;
  }
`;

const MaxWidth = styled('div')`
  @media only screen and (max-width: 50rem) {
    width: 100%;
    position: relative;
  }
`;  

export default function BasicLayout({ children }) {
  const { title } = useSiteMetadata()
//   const data = useStaticQuery(graphql`
//   query BasicLayoutQuery {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//   }
// `)
  return (
    <>
      <ThemeProvider siteTitle={title}>
        <Helmet
          title={title}
          meta={[
            { name: 'description', content: 'Sample' },
            { name: 'keywords', content: 'parsertongue, Gus Hahn-Powell' },
          ]}
        >

          <link rel="stylesheet" href="https://cdn.rawgit.com/jpswalsh/academicons/master/css/academicons.min.css"/>

          <link rel="stylesheet" href="https://github.githubassets.com/assets/gist-embed-b3b573358bfc66d89e1e95dbf8319c09.css"/>
          <html lang="en" />

        </Helmet>
        <MDXProvider  components={mdxComponents}>
          <div
            style={{
              width: "80%",
              margin: "auto",
              paddingLeft: "6px",
              paddingTop: "8em",
            }}
          >
            {/* <h3>Basic layout</h3> */}
          {children}
          </div>
        </MDXProvider>
        <div
          style={{
            height: "2em",
            paddingBottom: "5em"
          }}
        ></div>
        <Footer/>
      </ThemeProvider>
    </>
  )
}

BasicLayout.propTypes = {
  children: PropTypes.node.isRequired,
}