exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-mdx": () => import("./../../../src/pages/about/index.mdx" /* webpackChunkName: "component---src-pages-about-index-mdx" */),
  "component---src-pages-availability-js": () => import("./../../../src/pages/availability.js" /* webpackChunkName: "component---src-pages-availability-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-mermaid-mdx": () => import("./../../../src/pages/blog/mermaid.mdx" /* webpackChunkName: "component---src-pages-blog-mermaid-mdx" */),
  "component---src-pages-courses-general-configuring-your-development-environment-index-mdx": () => import("./../../../src/pages/courses/general/configuring-your-development-environment/index.mdx" /* webpackChunkName: "component---src-pages-courses-general-configuring-your-development-environment-index-mdx" */),
  "component---src-pages-courses-hlt-1-index-mdx": () => import("./../../../src/pages/courses/hlt-1/index.mdx" /* webpackChunkName: "component---src-pages-courses-hlt-1-index-mdx" */),
  "component---src-pages-courses-hlt-1-revelio-js": () => import("./../../../src/pages/courses/hlt-1/revelio.js" /* webpackChunkName: "component---src-pages-courses-hlt-1-revelio-js" */),
  "component---src-pages-courses-hlt-1-schedule-index-mdx": () => import("./../../../src/pages/courses/hlt-1/schedule/index.mdx" /* webpackChunkName: "component---src-pages-courses-hlt-1-schedule-index-mdx" */),
  "component---src-pages-courses-hlt-1-unit-1-getting-started-index-mdx": () => import("./../../../src/pages/courses/hlt-1/unit-1-getting-started/index.mdx" /* webpackChunkName: "component---src-pages-courses-hlt-1-unit-1-getting-started-index-mdx" */),
  "component---src-pages-courses-hlt-1-unit-2-tokens-and-their-attributes-index-mdx": () => import("./../../../src/pages/courses/hlt-1/unit-2-tokens-and-their-attributes/index.mdx" /* webpackChunkName: "component---src-pages-courses-hlt-1-unit-2-tokens-and-their-attributes-index-mdx" */),
  "component---src-pages-courses-hlt-1-unit-3-tokenization-and-regular-expressions-index-mdx": () => import("./../../../src/pages/courses/hlt-1/unit-3-tokenization-and-regular-expressions/index.mdx" /* webpackChunkName: "component---src-pages-courses-hlt-1-unit-3-tokenization-and-regular-expressions-index-mdx" */),
  "component---src-pages-courses-hlt-1-unit-4-vector-representations-of-words-and-documents-index-mdx": () => import("./../../../src/pages/courses/hlt-1/unit-4-vector-representations-of-words-and-documents/index.mdx" /* webpackChunkName: "component---src-pages-courses-hlt-1-unit-4-vector-representations-of-words-and-documents-index-mdx" */),
  "component---src-pages-courses-hlt-1-unit-5-probability-basics-index-mdx": () => import("./../../../src/pages/courses/hlt-1/unit-5-probability-basics/index.mdx" /* webpackChunkName: "component---src-pages-courses-hlt-1-unit-5-probability-basics-index-mdx" */),
  "component---src-pages-courses-hlt-1-unit-6-comparing-vectors-index-mdx": () => import("./../../../src/pages/courses/hlt-1/unit-6-comparing-vectors/index.mdx" /* webpackChunkName: "component---src-pages-courses-hlt-1-unit-6-comparing-vectors-index-mdx" */),
  "component---src-pages-courses-hlt-1-unit-7-clustering-index-md": () => import("./../../../src/pages/courses/hlt-1/unit-7-clustering/index.md" /* webpackChunkName: "component---src-pages-courses-hlt-1-unit-7-clustering-index-md" */),
  "component---src-pages-courses-hlt-pro-assignments-exercise-1-description-index-mdx": () => import("./../../../src/pages/courses/hlt-pro/assignments/exercise-1/description/index.mdx" /* webpackChunkName: "component---src-pages-courses-hlt-pro-assignments-exercise-1-description-index-mdx" */),
  "component---src-pages-courses-hlt-pro-assignments-exercise-1-rubric-index-mdx": () => import("./../../../src/pages/courses/hlt-pro/assignments/exercise-1/rubric/index.mdx" /* webpackChunkName: "component---src-pages-courses-hlt-pro-assignments-exercise-1-rubric-index-mdx" */),
  "component---src-pages-courses-hlt-pro-assignments-exercise-2-description-index-mdx": () => import("./../../../src/pages/courses/hlt-pro/assignments/exercise-2/description/index.mdx" /* webpackChunkName: "component---src-pages-courses-hlt-pro-assignments-exercise-2-description-index-mdx" */),
  "component---src-pages-courses-hlt-pro-assignments-exercise-2-rubric-index-mdx": () => import("./../../../src/pages/courses/hlt-pro/assignments/exercise-2/rubric/index.mdx" /* webpackChunkName: "component---src-pages-courses-hlt-pro-assignments-exercise-2-rubric-index-mdx" */),
  "component---src-pages-courses-hlt-pro-assignments-exercise-3-description-index-mdx": () => import("./../../../src/pages/courses/hlt-pro/assignments/exercise-3/description/index.mdx" /* webpackChunkName: "component---src-pages-courses-hlt-pro-assignments-exercise-3-description-index-mdx" */),
  "component---src-pages-courses-hlt-pro-assignments-exercise-3-rubric-index-mdx": () => import("./../../../src/pages/courses/hlt-pro/assignments/exercise-3/rubric/index.mdx" /* webpackChunkName: "component---src-pages-courses-hlt-pro-assignments-exercise-3-rubric-index-mdx" */),
  "component---src-pages-courses-hlt-pro-index-mdx": () => import("./../../../src/pages/courses/hlt-pro/index.mdx" /* webpackChunkName: "component---src-pages-courses-hlt-pro-index-mdx" */),
  "component---src-pages-courses-hlt-pro-unit-1-planning-your-career-index-mdx": () => import("./../../../src/pages/courses/hlt-pro/unit-1/planning-your-career/index.mdx" /* webpackChunkName: "component---src-pages-courses-hlt-pro-unit-1-planning-your-career-index-mdx" */),
  "component---src-pages-courses-hlt-pro-unit-2-your-professional-profile-index-mdx": () => import("./../../../src/pages/courses/hlt-pro/unit-2/your-professional-profile/index.mdx" /* webpackChunkName: "component---src-pages-courses-hlt-pro-unit-2-your-professional-profile-index-mdx" */),
  "component---src-pages-courses-hlt-pro-unit-2-your-professional-profile-misc-mdx": () => import("./../../../src/pages/courses/hlt-pro/unit-2/your-professional-profile/misc.mdx" /* webpackChunkName: "component---src-pages-courses-hlt-pro-unit-2-your-professional-profile-misc-mdx" */),
  "component---src-pages-courses-hlt-pro-unit-3-preparing-for-interviews-index-mdx": () => import("./../../../src/pages/courses/hlt-pro/unit-3/preparing-for-interviews/index.mdx" /* webpackChunkName: "component---src-pages-courses-hlt-pro-unit-3-preparing-for-interviews-index-mdx" */),
  "component---src-pages-courses-index-mdx": () => import("./../../../src/pages/courses/index.mdx" /* webpackChunkName: "component---src-pages-courses-index-mdx" */),
  "component---src-pages-courses-python-index-mdx": () => import("./../../../src/pages/courses/python/index.mdx" /* webpackChunkName: "component---src-pages-courses-python-index-mdx" */),
  "component---src-pages-courses-snlp-1-assignments-rubrics-class-competition-draft-index-mdx": () => import("./../../../src/pages/courses/snlp-1/assignments/rubrics/class-competition/draft/index.mdx" /* webpackChunkName: "component---src-pages-courses-snlp-1-assignments-rubrics-class-competition-draft-index-mdx" */),
  "component---src-pages-courses-snlp-1-assignments-rubrics-class-competition-final-index-mdx": () => import("./../../../src/pages/courses/snlp-1/assignments/rubrics/class-competition/final/index.mdx" /* webpackChunkName: "component---src-pages-courses-snlp-1-assignments-rubrics-class-competition-final-index-mdx" */),
  "component---src-pages-courses-snlp-1-classification-using-logistic-regression-index-mdx": () => import("./../../../src/pages/courses/snlp-1/classification-using-logistic-regression/index.mdx" /* webpackChunkName: "component---src-pages-courses-snlp-1-classification-using-logistic-regression-index-mdx" */),
  "component---src-pages-courses-snlp-1-classification-using-naive-bayes-index-mdx": () => import("./../../../src/pages/courses/snlp-1/classification-using-naive-bayes/index.mdx" /* webpackChunkName: "component---src-pages-courses-snlp-1-classification-using-naive-bayes-index-mdx" */),
  "component---src-pages-courses-snlp-1-configuring-your-development-environment-index-mdx": () => import("./../../../src/pages/courses/snlp-1/configuring-your-development-environment/index.mdx" /* webpackChunkName: "component---src-pages-courses-snlp-1-configuring-your-development-environment-index-mdx" */),
  "component---src-pages-courses-snlp-1-dependency-parsing-index-mdx": () => import("./../../../src/pages/courses/snlp-1/dependency-parsing/index.mdx" /* webpackChunkName: "component---src-pages-courses-snlp-1-dependency-parsing-index-mdx" */),
  "component---src-pages-courses-snlp-1-index-mdx": () => import("./../../../src/pages/courses/snlp-1/index.mdx" /* webpackChunkName: "component---src-pages-courses-snlp-1-index-mdx" */),
  "component---src-pages-courses-snlp-1-nlp-basics-index-mdx": () => import("./../../../src/pages/courses/snlp-1/nlp-basics/index.mdx" /* webpackChunkName: "component---src-pages-courses-snlp-1-nlp-basics-index-mdx" */),
  "component---src-pages-courses-snlp-1-revelio-js": () => import("./../../../src/pages/courses/snlp-1/revelio.js" /* webpackChunkName: "component---src-pages-courses-snlp-1-revelio-js" */),
  "component---src-pages-courses-snlp-1-schedule-index-mdx": () => import("./../../../src/pages/courses/snlp-1/schedule/index.mdx" /* webpackChunkName: "component---src-pages-courses-snlp-1-schedule-index-mdx" */),
  "component---src-pages-courses-snlp-1-sequence-tagging-index-mdx": () => import("./../../../src/pages/courses/snlp-1/sequence-tagging/index.mdx" /* webpackChunkName: "component---src-pages-courses-snlp-1-sequence-tagging-index-mdx" */),
  "component---src-pages-courses-snlp-1-word-representations-index-mdx": () => import("./../../../src/pages/courses/snlp-1/word-representations/index.mdx" /* webpackChunkName: "component---src-pages-courses-snlp-1-word-representations-index-mdx" */),
  "component---src-pages-courses-snlp-2-assignments-rubrics-class-competition-draft-index-mdx": () => import("./../../../src/pages/courses/snlp-2/assignments/rubrics/class-competition/draft/index.mdx" /* webpackChunkName: "component---src-pages-courses-snlp-2-assignments-rubrics-class-competition-draft-index-mdx" */),
  "component---src-pages-courses-snlp-2-assignments-rubrics-class-competition-final-index-mdx": () => import("./../../../src/pages/courses/snlp-2/assignments/rubrics/class-competition/final/index.mdx" /* webpackChunkName: "component---src-pages-courses-snlp-2-assignments-rubrics-class-competition-final-index-mdx" */),
  "component---src-pages-courses-snlp-2-assignments-rubrics-course-project-draft-index-mdx": () => import("./../../../src/pages/courses/snlp-2/assignments/rubrics/course-project/draft/index.mdx" /* webpackChunkName: "component---src-pages-courses-snlp-2-assignments-rubrics-course-project-draft-index-mdx" */),
  "component---src-pages-courses-snlp-2-assignments-rubrics-course-project-final-index-mdx": () => import("./../../../src/pages/courses/snlp-2/assignments/rubrics/course-project/final/index.mdx" /* webpackChunkName: "component---src-pages-courses-snlp-2-assignments-rubrics-course-project-final-index-mdx" */),
  "component---src-pages-courses-snlp-2-assignments-rubrics-paper-summary-draft-index-mdx": () => import("./../../../src/pages/courses/snlp-2/assignments/rubrics/paper-summary/draft/index.mdx" /* webpackChunkName: "component---src-pages-courses-snlp-2-assignments-rubrics-paper-summary-draft-index-mdx" */),
  "component---src-pages-courses-snlp-2-assignments-rubrics-paper-summary-final-index-mdx": () => import("./../../../src/pages/courses/snlp-2/assignments/rubrics/paper-summary/final/index.mdx" /* webpackChunkName: "component---src-pages-courses-snlp-2-assignments-rubrics-paper-summary-final-index-mdx" */),
  "component---src-pages-courses-snlp-2-cnn-basics-index-mdx": () => import("./../../../src/pages/courses/snlp-2/cnn-basics/index.mdx" /* webpackChunkName: "component---src-pages-courses-snlp-2-cnn-basics-index-mdx" */),
  "component---src-pages-courses-snlp-2-deep-and-simple-index-mdx": () => import("./../../../src/pages/courses/snlp-2/deep-and-simple/index.mdx" /* webpackChunkName: "component---src-pages-courses-snlp-2-deep-and-simple-index-mdx" */),
  "component---src-pages-courses-snlp-2-index-mdx": () => import("./../../../src/pages/courses/snlp-2/index.mdx" /* webpackChunkName: "component---src-pages-courses-snlp-2-index-mdx" */),
  "component---src-pages-courses-snlp-2-intro-dl-for-nlp-index-mdx": () => import("./../../../src/pages/courses/snlp-2/intro-dl-for-nlp/index.mdx" /* webpackChunkName: "component---src-pages-courses-snlp-2-intro-dl-for-nlp-index-mdx" */),
  "component---src-pages-courses-snlp-2-part-2-cnns-for-nlp-index-mdx": () => import("./../../../src/pages/courses/snlp-2/part-2/cnns-for-nlp/index.mdx" /* webpackChunkName: "component---src-pages-courses-snlp-2-part-2-cnns-for-nlp-index-mdx" */),
  "component---src-pages-courses-snlp-2-part-3-using-the-ua-hpc-index-mdx": () => import("./../../../src/pages/courses/snlp-2/part-3/using-the-ua-hpc/index.mdx" /* webpackChunkName: "component---src-pages-courses-snlp-2-part-3-using-the-ua-hpc-index-mdx" */),
  "component---src-pages-courses-snlp-2-part-4-tips-and-tricks-index-mdx": () => import("./../../../src/pages/courses/snlp-2/part-4/tips-and-tricks/index.mdx" /* webpackChunkName: "component---src-pages-courses-snlp-2-part-4-tips-and-tricks-index-mdx" */),
  "component---src-pages-courses-snlp-2-part-5-rnns-for-nlp-index-mdx": () => import("./../../../src/pages/courses/snlp-2/part-5/rnns-for-nlp/index.mdx" /* webpackChunkName: "component---src-pages-courses-snlp-2-part-5-rnns-for-nlp-index-mdx" */),
  "component---src-pages-courses-snlp-2-part-6-transformers-for-nlp-index-mdx": () => import("./../../../src/pages/courses/snlp-2/part-6/transformers-for-nlp/index.mdx" /* webpackChunkName: "component---src-pages-courses-snlp-2-part-6-transformers-for-nlp-index-mdx" */),
  "component---src-pages-courses-snlp-2-pytorch-dataloaders-index-mdx": () => import("./../../../src/pages/courses/snlp-2/pytorch-dataloaders/index.mdx" /* webpackChunkName: "component---src-pages-courses-snlp-2-pytorch-dataloaders-index-mdx" */),
  "component---src-pages-courses-snlp-2-pytorch-intro-index-mdx": () => import("./../../../src/pages/courses/snlp-2/pytorch-intro/index.mdx" /* webpackChunkName: "component---src-pages-courses-snlp-2-pytorch-intro-index-mdx" */),
  "component---src-pages-courses-snlp-2-revelio-js": () => import("./../../../src/pages/courses/snlp-2/revelio.js" /* webpackChunkName: "component---src-pages-courses-snlp-2-revelio-js" */),
  "component---src-pages-courses-snlp-2-why-dl-index-mdx": () => import("./../../../src/pages/courses/snlp-2/why-dl/index.mdx" /* webpackChunkName: "component---src-pages-courses-snlp-2-why-dl-index-mdx" */),
  "component---src-pages-cv-js": () => import("./../../../src/pages/cv.js" /* webpackChunkName: "component---src-pages-cv-js" */),
  "component---src-pages-cv-pdf-js": () => import("./../../../src/pages/cv.pdf.js" /* webpackChunkName: "component---src-pages-cv-pdf-js" */),
  "component---src-pages-hlt-faq-mdx": () => import("./../../../src/pages/hlt/faq.mdx" /* webpackChunkName: "component---src-pages-hlt-faq-mdx" */),
  "component---src-pages-hlt-resources-index-mdx": () => import("./../../../src/pages/hlt/resources/index.mdx" /* webpackChunkName: "component---src-pages-hlt-resources-index-mdx" */),
  "component---src-pages-hlt-welcome-mdx": () => import("./../../../src/pages/hlt/welcome.mdx" /* webpackChunkName: "component---src-pages-hlt-welcome-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-misc-logikcull-ml-engineer-hw-index-mdx": () => import("./../../../src/pages/misc/logikcull-ml-engineer-hw/index.mdx" /* webpackChunkName: "component---src-pages-misc-logikcull-ml-engineer-hw-index-mdx" */),
  "component---src-pages-news-cdc-covid-disparities-intiative-mdx": () => import("./../../../src/pages/news/cdc-covid-disparities-intiative.mdx" /* webpackChunkName: "component---src-pages-news-cdc-covid-disparities-intiative-mdx" */),
  "component---src-pages-news-colang-2024-db-workshop-mdx": () => import("./../../../src/pages/news/colang-2024-db-workshop.mdx" /* webpackChunkName: "component---src-pages-news-colang-2024-db-workshop-mdx" */),
  "component---src-pages-news-hiring-for-azahead-mdx": () => import("./../../../src/pages/news/hiring-for-azahead.mdx" /* webpackChunkName: "component---src-pages-news-hiring-for-azahead-mdx" */),
  "component---src-pages-news-hiring-for-logx-index-mdx": () => import("./../../../src/pages/news/hiring-for-logx/index.mdx" /* webpackChunkName: "component---src-pages-news-hiring-for-logx-index-mdx" */),
  "component---src-pages-news-hiring-for-nsf-rules-fall-2023-mdx": () => import("./../../../src/pages/news/hiring-for-nsf-rules-fall-2023.mdx" /* webpackChunkName: "component---src-pages-news-hiring-for-nsf-rules-fall-2023-mdx" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-news-nsf-partnerships-for-innovation-mdx": () => import("./../../../src/pages/news/nsf-partnerships-for-innovation.mdx" /* webpackChunkName: "component---src-pages-news-nsf-partnerships-for-innovation-mdx" */),
  "component---src-pages-news-nsf-rules-rule-mdx": () => import("./../../../src/pages/news/nsf-rules-rule.mdx" /* webpackChunkName: "component---src-pages-news-nsf-rules-rule-mdx" */),
  "component---src-pages-news-returning-to-ua-fall-2023-mdx": () => import("./../../../src/pages/news/returning-to-ua-fall-2023.mdx" /* webpackChunkName: "component---src-pages-news-returning-to-ua-fall-2023-mdx" */),
  "component---src-pages-news-ua-loa-mdx": () => import("./../../../src/pages/news/ua-loa.mdx" /* webpackChunkName: "component---src-pages-news-ua-loa-mdx" */),
  "component---src-pages-news-uncc-text-to-kg-seed-grant-mdx": () => import("./../../../src/pages/news/uncc-text-to-kg-seed-grant.mdx" /* webpackChunkName: "component---src-pages-news-uncc-text-to-kg-seed-grant-mdx" */),
  "component---src-pages-preprints-construction-of-knowledge-in-science-mdx": () => import("./../../../src/pages/preprints/construction-of-knowledge-in-science.mdx" /* webpackChunkName: "component---src-pages-preprints-construction-of-knowledge-in-science-mdx" */),
  "component---src-pages-presentations-2010-secol-worthy-of-attention-collostruction-mdx": () => import("./../../../src/pages/presentations/2010/secol-worthy-of-attention-collostruction.mdx" /* webpackChunkName: "component---src-pages-presentations-2010-secol-worthy-of-attention-collostruction-mdx" */),
  "component---src-pages-presentations-2014-asa-at-mdx": () => import("./../../../src/pages/presentations/2014/asa-at.mdx" /* webpackChunkName: "component---src-pages-presentations-2014-asa-at-mdx" */),
  "component---src-pages-presentations-2014-asa-testing-at-mdx": () => import("./../../../src/pages/presentations/2014/asa-testing-at.mdx" /* webpackChunkName: "component---src-pages-presentations-2014-asa-testing-at-mdx" */),
  "component---src-pages-presentations-2015-ultrafest-at-mdx": () => import("./../../../src/pages/presentations/2015/ultrafest-at.mdx" /* webpackChunkName: "component---src-pages-presentations-2015-ultrafest-at-mdx" */),
  "component---src-pages-presentations-2015-ultrafest-tongue-traces-mdx": () => import("./../../../src/pages/presentations/2015/ultrafest-tongue-traces.mdx" /* webpackChunkName: "component---src-pages-presentations-2015-ultrafest-tongue-traces-mdx" */),
  "component---src-pages-presentations-2015-ultrafest-ultrapraat-mdx": () => import("./../../../src/pages/presentations/2015/ultrafest-ultrapraat.mdx" /* webpackChunkName: "component---src-pages-presentations-2015-ultrafest-ultrapraat-mdx" */),
  "component---src-pages-projects-index-mdx": () => import("./../../../src/pages/projects/index.mdx" /* webpackChunkName: "component---src-pages-projects-index-mdx" */),
  "component---src-pages-projects-logx-index-mdx": () => import("./../../../src/pages/projects/logx/index.mdx" /* webpackChunkName: "component---src-pages-projects-logx-index-mdx" */),
  "component---src-pages-resume-js": () => import("./../../../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */),
  "component---src-pages-software-index-mdx": () => import("./../../../src/pages/software/index.mdx" /* webpackChunkName: "component---src-pages-software-index-mdx" */),
  "component---src-pages-students-prospective-students-mdx": () => import("./../../../src/pages/students/prospective-students.mdx" /* webpackChunkName: "component---src-pages-students-prospective-students-mdx" */),
  "component---src-pages-test-mdx": () => import("./../../../src/pages/test.mdx" /* webpackChunkName: "component---src-pages-test-mdx" */),
  "component---src-pages-tutorials-clustering-basics-index-mdx": () => import("./../../../src/pages/tutorials/clustering-basics/index.mdx" /* webpackChunkName: "component---src-pages-tutorials-clustering-basics-index-mdx" */),
  "component---src-pages-tutorials-containers-intro-index-md": () => import("./../../../src/pages/tutorials/containers-intro/index.md" /* webpackChunkName: "component---src-pages-tutorials-containers-intro-index-md" */),
  "component---src-pages-tutorials-distance-and-similarity-index-mdx": () => import("./../../../src/pages/tutorials/distance-and-similarity/index.mdx" /* webpackChunkName: "component---src-pages-tutorials-distance-and-similarity-index-mdx" */),
  "component---src-pages-tutorials-dl-for-nlp-tips-and-tricks-index-mdx": () => import("./../../../src/pages/tutorials/dl-for-nlp-tips-and-tricks/index.mdx" /* webpackChunkName: "component---src-pages-tutorials-dl-for-nlp-tips-and-tricks-index-mdx" */),
  "component---src-pages-tutorials-docker-basics-index-mdx": () => import("./../../../src/pages/tutorials/docker-basics/index.mdx" /* webpackChunkName: "component---src-pages-tutorials-docker-basics-index-mdx" */),
  "component---src-pages-tutorials-feature-vectors-index-mdx": () => import("./../../../src/pages/tutorials/feature-vectors/index.mdx" /* webpackChunkName: "component---src-pages-tutorials-feature-vectors-index-mdx" */),
  "component---src-pages-tutorials-git-basics-index-md": () => import("./../../../src/pages/tutorials/git-basics/index.md" /* webpackChunkName: "component---src-pages-tutorials-git-basics-index-md" */),
  "component---src-pages-tutorials-github-intro-index-md": () => import("./../../../src/pages/tutorials/github-intro/index.md" /* webpackChunkName: "component---src-pages-tutorials-github-intro-index-md" */),
  "component---src-pages-tutorials-js": () => import("./../../../src/pages/tutorials.js" /* webpackChunkName: "component---src-pages-tutorials-js" */),
  "component---src-pages-tutorials-lang-jpn-index-md": () => import("./../../../src/pages/tutorials/lang/jpn/index.md" /* webpackChunkName: "component---src-pages-tutorials-lang-jpn-index-md" */),
  "component---src-pages-tutorials-language-models-beginner-index-mdx": () => import("./../../../src/pages/tutorials/language-models-beginner/index.mdx" /* webpackChunkName: "component---src-pages-tutorials-language-models-beginner-index-mdx" */),
  "component---src-pages-tutorials-linux-command-line-basics-index-md": () => import("./../../../src/pages/tutorials/linux-command-line-basics/index.md" /* webpackChunkName: "component---src-pages-tutorials-linux-command-line-basics-index-md" */),
  "component---src-pages-tutorials-n-grams-index-mdx": () => import("./../../../src/pages/tutorials/n-grams/index.mdx" /* webpackChunkName: "component---src-pages-tutorials-n-grams-index-mdx" */),
  "component---src-pages-tutorials-named-entities-index-md": () => import("./../../../src/pages/tutorials/named-entities/index.md" /* webpackChunkName: "component---src-pages-tutorials-named-entities-index-md" */),
  "component---src-pages-tutorials-parts-of-speech-index-md": () => import("./../../../src/pages/tutorials/parts-of-speech/index.md" /* webpackChunkName: "component---src-pages-tutorials-parts-of-speech-index-md" */),
  "component---src-pages-tutorials-probability-basics-index-mdx": () => import("./../../../src/pages/tutorials/probability-basics/index.mdx" /* webpackChunkName: "component---src-pages-tutorials-probability-basics-index-mdx" */),
  "component---src-pages-tutorials-regex-tokenizer-index-md": () => import("./../../../src/pages/tutorials/regex-tokenizer/index.md" /* webpackChunkName: "component---src-pages-tutorials-regex-tokenizer-index-md" */),
  "component---src-pages-tutorials-regular-expressions-beginner-index-mdx": () => import("./../../../src/pages/tutorials/regular-expressions-beginner/index.mdx" /* webpackChunkName: "component---src-pages-tutorials-regular-expressions-beginner-index-mdx" */),
  "component---src-pages-tutorials-rnns-for-nlp-index-mdx": () => import("./../../../src/pages/tutorials/rnns-for-nlp/index.mdx" /* webpackChunkName: "component---src-pages-tutorials-rnns-for-nlp-index-mdx" */),
  "component---src-pages-tutorials-sklearn-custom-preprocessors-index-mdx": () => import("./../../../src/pages/tutorials/sklearn-custom-preprocessors/index.mdx" /* webpackChunkName: "component---src-pages-tutorials-sklearn-custom-preprocessors-index-mdx" */),
  "component---src-pages-tutorials-sklearn-intro-index-mdx": () => import("./../../../src/pages/tutorials/sklearn-intro/index.mdx" /* webpackChunkName: "component---src-pages-tutorials-sklearn-intro-index-mdx" */),
  "component---src-pages-tutorials-state-machines-regex-index-mdx": () => import("./../../../src/pages/tutorials/state-machines-regex/index.mdx" /* webpackChunkName: "component---src-pages-tutorials-state-machines-regex-index-mdx" */),
  "component---src-pages-tutorials-text-normalization-index-md": () => import("./../../../src/pages/tutorials/text-normalization/index.md" /* webpackChunkName: "component---src-pages-tutorials-text-normalization-index-md" */),
  "component---src-pages-tutorials-tokens-index-md": () => import("./../../../src/pages/tutorials/tokens/index.md" /* webpackChunkName: "component---src-pages-tutorials-tokens-index-md" */),
  "component---src-pages-tutorials-transformers-for-nlp-index-mdx": () => import("./../../../src/pages/tutorials/transformers-for-nlp/index.mdx" /* webpackChunkName: "component---src-pages-tutorials-transformers-for-nlp-index-mdx" */),
  "component---src-pages-tutorials-ubuntu-install-docker-index-md": () => import("./../../../src/pages/tutorials/ubuntu-install-docker/index.md" /* webpackChunkName: "component---src-pages-tutorials-ubuntu-install-docker-index-md" */),
  "component---src-pages-tutorials-ubuntu-install-vscode-index-md": () => import("./../../../src/pages/tutorials/ubuntu-install-vscode/index.md" /* webpackChunkName: "component---src-pages-tutorials-ubuntu-install-vscode-index-md" */),
  "component---src-pages-tutorials-using-the-ua-hpc-index-mdx": () => import("./../../../src/pages/tutorials/using-the-ua-hpc/index.mdx" /* webpackChunkName: "component---src-pages-tutorials-using-the-ua-hpc-index-mdx" */),
  "component---src-pages-tutorials-vector-basics-index-mdx": () => import("./../../../src/pages/tutorials/vector-basics/index.mdx" /* webpackChunkName: "component---src-pages-tutorials-vector-basics-index-mdx" */),
  "component---src-pages-tutorials-virtualbox-install-ubuntu-index-mdx": () => import("./../../../src/pages/tutorials/virtualbox-install-ubuntu/index.mdx" /* webpackChunkName: "component---src-pages-tutorials-virtualbox-install-ubuntu-index-mdx" */),
  "component---src-pages-workshops-resbaz-2022-intro-to-nlp-index-mdx": () => import("./../../../src/pages/workshops/resbaz-2022/intro-to-nlp/index.mdx" /* webpackChunkName: "component---src-pages-workshops-resbaz-2022-intro-to-nlp-index-mdx" */)
}

