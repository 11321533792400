import React, { useState } from 'react';
import { Link } from 'gatsby';
import { 
  GitHubPage, 
  TwitterAccount,
  ORCIDProfile,
  GoogleScholarProfile,
  LinkedInProfile
} from 'src/components/icons';

import {
  Alignment,
  Button,
  Classes,
  Drawer,
  H5,
  Icon,
  IconSize,
  Navbar,
  NavbarDivider,
  NavbarGroup,
  NavbarHeading,
  Switch,
} from "@blueprintjs/core";

//import { navigate } from '@reach/router'
function Header({ siteTitle }) {
  const [visible, setVisibility] = useState(false);
  const toggleVisibility = () => setVisibility(visible => 
    !visible
  );
  console.log(visible)
  return (
    <Navbar
      fixedToTop
      className="bp3-dark"
    >
    <Navbar.Group align={Alignment.LEFT}>
      <Button
        className={`bp3-minimal`}
        icon={<Icon icon="menu" size={32} />}
        text=""
        onClick={toggleVisibility}
      />
    </Navbar.Group>
    <Drawer
      title={""}
      style={{ left: '0'}} 
      isOpen={visible}
      className={`nav-menu bp3-dark`}
      // icon="info-sign"
      size={'140px'}
      transitionDuration={0}
      isCloseButtonShown={false}
      usePortal={true} 
      hasBackdrop={false} 
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      onClose={toggleVisibility}
    >
      <div className={Classes.DRAWER_BODY}>
        <p>
        <Link
          to="/"
        >
          <Button
            className={`menu-button bp3-minimal menu-home`}
            text={`cd ~/`}
          />
        </Link>
        </p>
        <hr/>
        <p>
        <Link
          to="/courses"
        >
          <Button
            className={`menu-button bp3-minimal`}
            text="courses"
          />
        </Link>
        </p>
        <p>
        <Link
          to="/software"
          style={{
            ...styles.disabledLink,
          }}
        >
          <Button
            className={`menu-button bp3-minimal`}
            text="software"
            disabled
          />
        </Link>
        </p>
        <p>
        <Link
          to="/students"
          style={{
            ...styles.disabledLink,
          }}
        >
          <Button
            className={`menu-button bp3-minimal`}
            text="students"
            disabled
          />
        </Link>
        </p>
        <p>
        <Link
          to="/projects"
          style={{
            ...styles.disabledLink,
          }}
        >
          <Button
            className={`menu-button bp3-minimal`}
            text="research"
            disabled
          />
        </Link>
        </p>
        <hr/>
        <p>
        <Link
          to="/about"
        >
          <Button
            className={`menu-button bp3-minimal`}
            text="about"
          />
        </Link>
        </p>
        <p>
        <Link
          to="/availability"
        >
          <Button
            className={`menu-button bp3-minimal`}
            text="availability"
          />
        </Link>
        </p>
        <p>
        <Link
          to="/blog"
          style={{
            ...styles.disabledLink,
          }}
        >
          <Button
            className={`menu-button bp3-minimal`}
            text="blog"
            disabled
          />
        </Link>
        </p>
        <hr/>
        <p>
        <Link
          // to="/cv.pdf"
          to="https://public.parsertongue.org/cv.pdf"
        >
          <Button
            className={`menu-button bp3-minimal`}
            icon="document"
            text="CV"
          />
        </Link>
        </p>
        <hr/>
        <p>
        <Button
          className={`menu-button bp3-minimal`}
        >
          <GitHubPage
            account="myedibleenso"
            style={{
              fontSize: "1.3em",
            }}
          />
        </ Button >
        <Button
          className={`menu-button bp3-minimal`}
        >
          <TwitterAccount
            account="myedibleenso"
            style={{
              fontSize: "1.3em",
            }}
          />
        </ Button >
        </p>
        <p>
        <Button
          className={`menu-button bp3-minimal`}
        >
          <ORCIDProfile
            style={{
              paddingBottom: '4px',
              fontSize: "1.5em",
            }}
          />
        </ Button >

        <Button
          className={`menu-button bp3-minimal`}
        >
          <LinkedInProfile
            style={{
              paddingBottom: '4px',
              fontSize: "1.5em",
            }}
          />
        </ Button >
        </p>
        {/* <div className={Classes.DIALOG_BODY}>
            <p>I am sample body text!!</p>

        </div> */}
    </div>
    </Drawer>
    </Navbar>
  )
}

// function HeaderOld({ siteTitle }) {
//   return (
//     <Navbar
//       fixedToTop
//       className="bp3-dark"
//     >
//       <Navbar.Group align={Alignment.LEFT}>
//         <Link
//           to="/"
//         >
//           <Navbar.Heading
//             // style={{
//             //   fontSize: "20px",
//             // }}
//           >
//             ~/
//           </Navbar.Heading>
//         </Link>  
//         <Navbar.Divider />
//         <Link
//           to="/blog"
//           style={{
//             ...styles.disabledLink,
//           }}
//         >
//           <Button
//             className={`menu-button bp3-minimal`}
//             text="blog"
//             disabled
//           />
//         </Link>
//         <Link
//           to="/courses"
//         >
//           <Button
//             className={`menu-button bp3-minimal`}
//             text="courses"
//           />
//         </Link>
//         <Link
//           to="/software"
//           style={{
//             ...styles.disabledLink,
//           }}
//         >
//           <Button
//             className={`menu-button bp3-minimal`}
//             text="software"
//             disabled
//           />
//         </Link>
//         <Link
//           to="/students"
//           style={{
//             ...styles.disabledLink,
//           }}
//         >
//           <Button
//             className={`menu-button bp3-minimal`}
//             text="students"
//             disabled
//           />
//         </Link>
//         <Link
//           to="/projects"
//         >
//           <Button
//             className={`menu-button bp3-minimal`}
//             text="projects"
//           />
//         </Link>
//         <Link
//           to="/about"
//         >
//           <Button
//             className={`menu-button bp3-minimal`}
//             text="about"
//           />
//         </Link>
//         <Navbar.Divider />
//         <Link
//           to="/cv.pdf"
//         >
//           <Button
//             className={`menu-button bp3-minimal`}
//             icon="document"
//             text="CV"
//           />
//         </Link>
//         <Button
//           className={`menu-button bp3-minimal`}
//         >
//           <GitHubPage
//             account="myedibleenso"
//             style={{
//               fontSize: "1.3em",
//             }}
//           />
//         </ Button >

//         <Button
//           className={`menu-button bp3-minimal`}
//         >
//           <TwitterAccount
//             account="myedibleenso"
//             style={{
//               fontSize: "1.3em",
//             }}
//           />
//         </ Button >

//         <Button
//           className={`menu-button bp3-minimal`}
//         >
//           <ORCIDProfile
//             style={{
//               paddingBottom: '4px',
//               fontSize: "1.5em",
//             }}
//           />
//         </ Button >

//         <Button
//           className={`menu-button bp3-minimal`}
//         >
//           <LinkedInProfile
//             style={{
//               paddingBottom: '4px',
//               fontSize: "1.5em",
//             }}
//           />
//         </ Button >
//         {/* <GoogleScholarProfile
//           style={{
//             ...styles.icon,
//           }}
//         /> */}
//         {/* <Link
//           to="/app/profile"
//           style={{
//             ...styles.headerTitle,
//             marginLeft: "10%"
//           }}
//         >
//           Profile
//         </Link> */}
//       </Navbar.Group>
//     </Navbar>
//   )
// } 


const styles = {
  headerTitle: {
    color: 'white',
    fontSize: '20px',
    textDecoration: 'none',
    marginLeft: "2%",
    marginRight: "2%",
  },
  disabledLink: {
    cursor: 'not-allowed',
    color: 'grey',
    pointerEvents: 'none'
  },
  link: {
    cursor: 'pointer',
    color: 'white',
    textDecoration: 'underline'
  },
  icon: {
    height: '20px',
    paddingBottom: '0px',
    marginBottom: '-3px',
    color: 'white'
  }
}

export default Header
